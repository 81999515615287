.emailRow {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid whitesmoke;
    border-radius: 20px;
    cursor: pointer;
    z-index: 999;
}

.emailRow:hover {
    border-top: 1px solid whitesmoke;
    box-shadow: 0px 4px 4px -2px#232273;
}

.emailRow__options {
    display: flex;
}

.emailRow__message {
    display: flex;
    flex: 0.8;
    align-items: center;
    font-size: 13px;
}

.emailRow__message > h4 {
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 5px;
    padding-right: 5px;
}

.emailRow__title {
    font-size: 13px;
    flex: 0.3;
}

.emailRow__time {
    padding-right: 15px;
    font-size: 9px;
    font-weight: bold;
}

.emailRow__description {
    font-weight: 400;
    color: gray;
}