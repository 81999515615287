.login {
  background-image: linear-gradient(#232273, #686884);
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
}

.login__container {
  padding-top: 70px;
  padding-bottom: 65px;
  text-align: center;
  background-color: white;
  border-radius: 40px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.register__container {
  padding-top: 35px;
  padding-bottom: 35px;
  text-align: center;
  background-color: white;
  border-radius: 40px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.login__container > img {
  object-fit: contain;
  height: 100px;
  margin-bottom: 40px;
}

.input {
  margin-top: 40px;
  outline-width: 0;
  border: none;
  padding: 15px 20px;
  border-radius: 999px;
}

.input-login {
  background: #e8f0fe;
  border: none;
  border-radius: 15px;
  height: 33px;
  width: 250px;
  margin-bottom: 15px;
  outline-width: 0;
  font-size: 16px;
  padding-left: 15px;
}

.input-login::placeholder {
  color: gray;
  font-size: 13px;
}

.label-login {
  font-size: 18px;
  margin-bottom: 7px;
  margin-top: 5px;
  display: inline-block;
  text-align: center;
}

.login-button,
.register-button {
  font-size: 16px;
  font-weight: bold;
  border-radius: 10px;
  width: 65%;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 1px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.login-button {
  color: #fff;
  background-image: linear-gradient(#232273, #686884);
}

.register-button {
  color: #232273;
  border-color: #232273;
}

.login-button:hover,
.register-button:hover {
  transform: scale(1.05); /* Scale up the button */
}

.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #d9534f;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
  display: flex;
  align-items: center;
}
