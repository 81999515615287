.header {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid whitesmoke;
  background-color: #232273;
}

.header-h1 {
  color: #fff;
  /* position: relative; */
}
.header__right {
  display: flex;
  position: absolute;
  right: 20px;
}
.header__left {
  display: inline;
  justify-content: space-evenly;
  position: absolute;
  left: 20px;
}

.header__left > img {
  object-fit: contain;
  height: 80px;
  margin-left: 5px;
}

.header__middle {
  display: flex;
  flex: 0.7;
  align-items: center;
  background-color: whitesmoke;
  padding: 10px;
  border-radius: 5px;
}

.header__middle > .MuiSvgIcon-root {
  color: gray;
}

.header__middle > input {
  border: none;
  width: 100%;
  padding: 10px;
  outline-width: 0;
  font-size: medium;
  background-color: transparent;
}
