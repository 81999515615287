.app {
    height: 100vh;
  }
  
  .app__body {
    display: flex;
    height: 100vh;
    /* background-image: url("https://c.wallhere.com/photos/b0/e9/alone_stars_purple_background-1964895.jpg!d"); */
    /* background-color: rgb(228, 228, 228); */

  }

/* .App {
  background-image: linear-gradient(#232273, #686884);
} */